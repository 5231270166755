<template>
  <div class="error-payment__wrapper">
    <div class="error-wrapper__content">
      <div class="error-wrapper">
        <div class="error-wrapper__icon">
          <img src="/img/payment/icon-error.svg" alt="">
        </div>
        <div class="error-wrapper__title">Что-то пошло не так!</div>
        <div id="errorBlock" class="error-wrapper__description">Ваш платеж не прошел</div>
        <a href="/" class="error-wrapper__button">Вернуться на сайт</a>
      </div>
    </div>


    <img class="error-payment__ui-left" src="@/assets/img/user/payment/element-ui-left.svg" alt="">
    <img class="error-payment__ui-right" src="@/assets/img/user/payment/element-ui-right.svg" alt="">
    <img class="error-payment__icon-online" src="@/assets/img/user/payment/icon-online.svg" alt="">
    <img class="error-payment__icon-pen" src="@/assets/img/user/payment/icon-pen.svg" alt="">
  </div>
</template>

<script>

export default {
  name: "ErrorPayment"
}
</script>

<style lang="scss" scoped>
@import "/style/error.css";
  .error-payment {

    &__wrapper {
      margin-top: 62px;
      min-height: 336px;
      padding: 48px 84px;
      background: rgba(20, 84, 242, 0.02);
      border-radius: 20px;

      transition: 0.2s;
      @media screen and (max-width: 980px) {
        padding: 36px 24px;
        margin: 16px 24px 0 24px;
      }
    }

    @media screen and (max-width: 1800px) {
      &__icon-pen,
      &__icon-online,
      &__ui-left,
      &__ui-right {
        display: none;
      }
    }

    &__icon-pen {
      position: absolute;
      right: 215px;
      top: 60%;
    }
    &__icon-online {
      position: absolute;
      left: 110px;
      top: 220px;
    }
    &__ui-left {
      position: absolute;
      left: 0;
      bottom: 300px;
      z-index: -1;
    }
    &__ui-right {
      position: absolute;
      right: 0;
      top: 100px;
      z-index: -1;
    }
  }
</style>